import React, { useState } from 'react'
import { putCouponCode } from 'apis/cart'
import type { ConnectorProps as CartProps } from 'connectors/Cart'
import CartConnector from 'connectors/Cart'
import type { ConnectorProps as LocaleProps } from 'connectors/Locale'
import LocaleConnector from 'connectors/Locale'
import { formatPrice, convertDefaultToLocaleCurrency } from 'components/FormatPrice/helpers'
import { CouponCode } from './styles'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

const APPLY = 'Apply Coupon'
const APPLIED = 'Coupon Applied!'

type Props = CartProps &
  LocaleProps & {
    code: string
    // "JOYFULSUMMER20"
    minimum: string
    // "5500"
    showError: (...args: Array<any>) => any
    title: string
    // "20% Off Originals of"
    toggleClose: (...args: Array<any>) => any
  }
type State = {
  isBlocked: boolean
  // API Call in progress, do NOT allow multiple calls
  text: string // Button Text
}
const DEFAULT_STATE: State = {
  isBlocked: false,
  text: APPLY,
}

const CouponCodeComponent = (props: Props): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const [state, updateState] = useState(DEFAULT_STATE)
  const { isBlocked, text } = state
  const { applyPromo, code, minimum, selectedCurrency, showError, title, toggleClose } = props
  const amount = formatPrice(
    convertDefaultToLocaleCurrency(Number(minimum), selectedCurrency),
    selectedCurrency
  )

  const applyCoupon = async () => {
    if (isBlocked) return
    updateState((prev) => ({ ...prev, isBlocked: true }))
    const { success } = await putCouponCode(code)

    if (success) {
      updateState((prev) => ({ ...prev, text: APPLIED }))
      setTimeout(function () {
        updateState(DEFAULT_STATE)
        toggleClose()
      }, 5000)

      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'promobar_coupon',
          message: code,
        })
      }

      // Lets FIRE a Redux Event
      applyPromo()
      return
    }

    // Fail - Reset
    updateState(DEFAULT_STATE)
    showError('Unable to apply coupon.')
  }

  return (
    <CouponCode onClick={applyCoupon} data-type='item'>
      <div data-type='info'>
        <SATypography data-type='title' variant={SATypographyVariant.CTA_MEDIUM}>
          {title} {amount}+
        </SATypography>
        <SATypography bold data-type='code' variant={SATypographyVariant.CTA_MEDIUM}>
          {code}
        </SATypography>
      </div>
      <SATypography data-type='apply' variant={SATypographyVariant.CTA_MEDIUM}>
        {text}
      </SATypography>
    </CouponCode>
  )
}

export default CartConnector(LocaleConnector(CouponCodeComponent))