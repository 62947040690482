import React, { useState } from 'react'
import Countdown from './Countdown'
import CouponCode from './CouponCode'
import { Promobar } from './styles'
import { PromoCampaign } from './Types'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

type Props = {
  addMessage: (...args: Array<any>) => any
  currentPromo: PromoCampaign
}

const PromoCampaignComponent = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const { addMessage, currentPromo } = props
  const [isMobile, setIsMobile] = useState(false)
  const status = isMobile ? 'show' : 'hide'
  const {
    color,
    // : "A50202"
    countdown,
    // : "0"
    mobileTagline,
    // : "Joyful Art for Sunnier Days"
    periodEndUnix,
    // : 1657339200
    tagline,
    // : "Joyful Artwork for Sunnier Days"
    tiers, // : (2) [{…}, {…}]
  } = currentPromo || {}
  return (
    <Promobar data-section='promo_bar' background={color}>
      <div className='wrapper'>
        <div
          className='mobile'
          onClick={() => setIsMobile(!isMobile)}
          onKeyPress={() => setIsMobile(!isMobile)}
          role='button'
          tabIndex='0'
        >
          <SATypography customClassName='mobile-tagline' variant={SATypographyVariant.CTA_MEDIUM}>{mobileTagline}</SATypography>
          <SATypography customClassName='offers-toggle' variant={SATypographyVariant.CTA_MEDIUM}>
            See Offers ({tiers.length}) <span className={`caret ${status}`} />
          </SATypography>
        </div>
        <div className={`layout ${status}`}>
          <SATypography customClassName='tagline' variant={SATypographyVariant.CTA_MEDIUM}>
            {tagline}
            {countdown === '1' && <Countdown endTimestamp={periodEndUnix} />}
          </SATypography>
          <div data-section='coupon-list'>
            {tiers.map((tier, key) => (
              <CouponCode
                key={key}
                {...tier}
                toggleClose={() => setIsMobile(false)}
                showError={(message) =>
                  addMessage({
                    type: 'error',
                    messages: [message],
                  })
                }
              />
            ))}
          </div>
        </div>
      </div>
    </Promobar>
  )
}

export default PromoCampaignComponent