import React, { useEffect, useState } from 'react'
import SALink from 'components/SALink'
import { handleScreenReader } from 'lib/screenReader'
import ExclamationCircleIcon from 'svg/exclamation-circle.svg'
import { resetNotification, showNotification } from './helper'
import {
  CartUrgencyWrapper,
  CloseIcon,
  CartUrgencyTextContainer,
  CartUrgencyCloseIconContainer,
} from './styles'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyColor, SATypographyVariant } from 'components/SATypography/SATypography.model'

const CartUrgencyBar = (): React.ReactElement<React.ComponentProps<any>, any> => {
  const [isVisible, setIsVisible] = useState(false)
  const [triggerCloseAnimation, setTriggerCloseAnimation] = useState(false)

  useEffect(() => {
    const canShow = showNotification()
    setIsVisible(canShow)
  }, [])

  const handleCloseClick = () => {
    resetNotification()
    setIsVisible(false)
    setTriggerCloseAnimation(true)
  }

  const shouldCloseAnimation = triggerCloseAnimation ? 'slideUp' : ''
  const animation = isVisible ? 'slideDown' : shouldCloseAnimation

  return (
    <CartUrgencyWrapper data-animation={animation}>
      <CartUrgencyTextContainer>
        <ExclamationCircleIcon />
        <SATypography color={SATypographyColor.WHITE} variant={SATypographyVariant.CTA_SMALL}>
          Artworks In Your Cart Are Not Reserved.
        </SATypography>
        <SATypography color={SATypographyColor.WHITE} variant={SATypographyVariant.CTA_SMALL}>
          <SALink href='/checkout/shipping-billing?ucb-checkout-now' data-style='cart-link'>
            Checkout Now
          </SALink>
        </SATypography>
      </CartUrgencyTextContainer>
      <CartUrgencyCloseIconContainer>
        <div
          data-style='icon-clickable-div'
          role='button'
          tabIndex='0'
          onClick={handleCloseClick}
          onKeyDown={(ev) => {
            handleScreenReader(ev) && handleCloseClick()
          }}
        >
          <CloseIcon />
        </div>
      </CartUrgencyCloseIconContainer>
    </CartUrgencyWrapper>
  )
}

export default CartUrgencyBar
